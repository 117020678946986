<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Nueva Internacion</h1>
        </div>
      </div>
      <!-- aqui va la transicion con la barra informativa superior -->
      <transition name="fade" v-if="showCortinaInformativa">
        <div class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div class="col-sm-12 py-2 bg-sky-light text-white">
              <div class="row">
                <div class="col-2">
                  <b>Edad:</b> {{dataPaciente.edad}} años
                </div>
                <div class="col-1">
                  <b>H.C:</b> {{dataPaciente.numero}}
                </div>
                <div class="col-4" v-if="dataPaciente.contacto">
                  <p style="font-size: 12px">Contacto 1: {{ dataPaciente.contacto }} </p>
                </div>
                <div class="col-4">
                  <p style="font-size: 12px" v-if="dataPaciente.familiar">
                    Familiar 1:
                    {{ dataPaciente.familiar }} </p>
                </div>
                <div
                  v-if="dataPaciente.tipo_seguro==='Conjunto'"
                  class="col-sm-3 px-1"
                >
                  <b>Seguro:</b> {{dataPaciente.cobertura}}
                  <span v-if="dataPaciente.tipo_familiar">
                    ({{dataPaciente.tipo_familiar}})
                  </span>
                </div>
                <div
                  v-if="dataPaciente.numero_poliza || dataPaciente.cod_asegurado"
                  class="col-sm-2 px-1"
                >
                  <template v-if="dataPaciente.numero_poliza">
                    <b>N° Poliza: </b>{{dataPaciente.numero_poliza}}
                  </template>
                  <template v-else>
                    <b>N° Cod.:</b>{{dataPaciente.cod_asegurado}}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <button
                  type="submit"
                  class="btn bg-custom-green mr-2 rounded"
                  @click="store"
                >
                  Guardar
                </button>
                <router-link :to="{ name: 'internacion-index' }">
                  <button
                    class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
              <div class="col-sm-7 text-right">
                <div class="row">
                  <div class="col">
                    <label class="col-form-label">
                      Los medicamentos se proporcionarán de Farmacia:
                    </label>
                    <button
                    class="ml-2 px-2 btn "
                    :class="medicationsProvidesByPharmacy === false ? 'btn-danger':'btn-secondary'"
                    @click="medicationsProvidesByPharmacy = false"
                  >
                    Rechazado
                  </button>
                  <button
                    class="ml-2 px-2 btn btn-success"
                    :class="medicationsProvidesByPharmacy === true ? 'bg-abierto':'btn-success'"
                    @click="openModalAutorizacionInternacion"
                  >
                    Confirmado
                  </button>
                    <label
                      v-if="medicationsProvidesByPharmacy === true"
                      class="col-form-label ml-2 px-2 ">Imprimir
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <TheModalPreCierre
                  ref="theModalPreCierre"
                  :servicios_internos="servicios"
                  :cxp="cuentas_x_pagar"
                  :paciente="dataPaciente"
                  :preCierreData="preCierreData"
                  :salidProducto="salidaProductos.detalles"
                  :moneda="moneda"
                  @agregarPrecierre="agregarPrecierre"
                  @update:estado_precierre="preCierre=$event"
                  v-on:limpiarPreCierre="limpiarPreCierre"
                  @agregarCierre="agregarCierre"
                  :serviciosComplementarios="servicioComplementario"
                />
                <ModalCierre
                  :preCierre="preCierre"
                  :preCierreData="preCierreData"
                  :cierreData="cierre"
                  @agregarCierre="agregarCierre"
                />
                <button
                class="ml-2 px-2 btn btn-secondary "
                @click="goToHistorialClinico"
              >
                H.C. Internacion
              </button>
              <button
              class="ml-2 px-2 btn btn-secondary "
            >
              Signos Vitales
            </button>
            <button
            class="ml-2 px-2 btn btn-secondary "
          >
            Historia Signos Vitales
          </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="mb-3">
                      <div class="row mb-3">
                        <div class="col-sm-2 text-right pr-0">
                          <label
                            for="fecha"
                            class="col-form-label"
                          >
                            Fecha:
                          </label>
                        </div>
                        <div class="col-sm-4 px-0">
                          <date-picker
                            :input-attr="{ id: 'fecha' }"
                            class="col-sm-10"
                            v-model="fecha"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 text-right pr-0">
                          <label
                            for="fechaCierre"
                            class="col-form-label"
                          >
                            Fecha Cierre:
                          </label>
                        </div>
                        <div class="col-sm-4 px-0">
                          <date-picker
                            :input-attr="{ id: 'fechaCierre' }"
                            class="col-sm-10"
                            v-model="fechaCierre"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-2 text-right pr-0">
                          <label
                            for="comboPaciente"
                            class="col-form-label"
                          >
                            Paciente:
                          </label>
                        </div>
                        <ComboPaciente
                          id="comboPaciente"
                          v-on:addPaciente="AddPaciente"
                          :havePreCierre="preCierre"
                        />
                        <div class="col-sm-1 px-0">
                          <button
                            class="btn btn-success col-sm-12 px-0"
                            @click="openModalPaciente"
                          >
                            <i class="i-Business-ManWoman h5"></i>
                          </button>
                          <ModalPacienteNuevo />
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b>Diagnóstico: </b><span class="text-danger">(Obligatorio)</span>
                      <div class="row mt-1 mb-2">
                        <div class="col-sm-12">
                          <multiselect2
                            id="comboDiagnostico"
                            v-model="clasificacionCIE"
                            :options="listaClasificacionCIE"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="id"
                            label="nombre"
                            placeholder="Seleccione un item"
                            :searchable="true"
                          >
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <input
                            id="inputDiagnostico"
                            v-model="diagnostico"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b class="text-light">Solicitar Cirugia:</b>
                      <div class="row mx-0 pt-1">
                        <QuirofanoItem v-for="(item, index) in cirugias" :key="index"
                          :indexCirugia="index+1"
                          @click.native="openModalCirugia(false)"
                        />
                        <QuirofanoItem
                          :modeAddCirugia="true"
                          @click.native="openModalCirugia(true)"
                        />
                      </div>
                      <TheModalCirugia
                        v-model="modeAddItem"
                        @addNewItem="addItemCirugia($event)"
                        @onChangeData="saveData($event)"
                      />
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="mb-4">
                      <div class="row mb-3">
                        <div class="col-sm-3 px-0 ml-2 text-right">
                          <label
                            for="comboTratamiento"
                            class="col-form-label text-skyblue font-weight-bold"
                          >
                            {{labelTratamiento}}:
                          </label>
                        </div>
                        <div class="col-sm-8">
                          <multiselect2
                            id="comboTratamiento"
                            v-model="categoriaTratamiento.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre"
                            label="nombre"
                            multiple
                            placeholder="Seleccione un item"
                            :options="categoriaTratamiento.listaCategorias"
                            :allow-empty="false"
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3 px-0 ml-2 text-right">
                          <label
                            for="comboMedicoPrincipal"
                            class="col-form-label text-skyblue font-weight-bold"
                          >
                            Medico Principal:
                          </label>
                        </div>
                        <div class="col-sm-8">
                          <multiselect2
                            id="comboMedicoPrincipal"
                            v-model="medico.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre"
                            label="nombre"
                            placeholder="Seleccione un medico"
                            :options="medico.listaMedicos"
                            :allow-empty="false"
                            multiple
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="isDisableSeguro">
                        <div class="col-sm-2 text-right pr-0">
                          <label
                            for="comboSeguro"
                            class="col-form-label text-light"
                          >
                            Seguro:
                          </label>
                        </div>
                        <div class="col-sm-7">
                          <multiselect2
                            id="comboSeguro"
                            v-model="seguro.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre_comercial"
                            label="nombre_comercial"
                            placeholder="Seleccione un seguro"
                            :options="seguro.listaSeguros"
                            :searchable="true"
                          >
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-1">
                          <button
                            class="btn col-sm-12"
                            :class="getIDItemChecked !== null? 'btn-success':'btn-warning'"
                            @click="openModalPieza"
                          >
                            Pieza
                          </button>
                          <ModalPieza :editMode="false"/>
                        </div>
                        <div class="col-sm-3 pr-1">
                          <input
                            id="inputCama"
                            :value="getCamaItemChecked"
                            type="text"
                            class="form-control text-center px-1"
                            disabled
                          >
                        </div>
                        <div class="col-sm-4 pl-1">
                          <input
                            id="inputPisoSala"
                            :value="getPisoSalaItemChecked"
                            type="text"
                            class="form-control text-center px-1"
                            disabled
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b class="text-light">Inter Consulta:</b>
                      <div class="row mx-0 pt-1">
                        <div
                          @click="openModalInterConsulta"
                          class="cursor-pointer col-sm-2 px-0 mr-3 my-2 border rounded"
                          style="background: #eee;height: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;"
                        >
                          <b
                            style="color:#858189;"
                          >
                            Inter 1
                          </b>
                        </div>
                        <div
                          @click="openModalInterConsulta"
                          class="cursor-pointer col-sm-2 px-0 mr-3 my-2 border rounded"
                          style="height: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;"
                        >
                          <span
                            class="h1 pt-2"
                            style="color:#858189;"
                          >+</span>
                        </div>
                      </div>
                      <TheModalInterConsulta />
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9 pl-4" style="display: inline;">
                <b><font size=3>Servicios Complementarios</font></b>
              </div>
              <div class="col-sm-3">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <multiselect2
                      id="comboAlmacen"
                      v-model="almacen.model"
                      selectLabel=""
                      selectedLabel=""
                      deselect-label="X"
                      track-by="almacen"
                      label="almacen"
                      placeholder="Seleccione Almacen"
                      :options="almacen.listaAlmacenes"
                      :disabled="isDisabledAlmacen"
                      :searchable="true"
                    >
                    </multiselect2>
                  </div>
                </div>
              </div>
            </div>
            <!-- servicios complementarios buttons -->
            <div class="row mb-2">
              <div class="col-sm-12" style="background: #f4b375;">
                <div class="form-group row">
                  <div class="col-sm-12 pt-2">
                    <the-modal-consumo-productos
                      :isActiveProductos="isActiveFarmacia"
                      :almacen="almacen.model"
                      :moneda="moneda.model"
                      :fecha="fecha"
                      :havePreCierre="preCierre"
                      :activateButton="nombreButtonActivo == 'productos' ? true : false"
                      v-on:addProducto="addProducto"
                      v-on:activateTheButton="activateTheButton"
                      :nameButton="getNameFarmacia"
                      ref="theModalConsumoProducto"
                    />
                    <button
                      v-if="isActiveLaboratorio"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-2'"
                      type="button"
                      :disabled="preCierre"
                      :class="getClassButtonLaboratorio"
                      @click="openModalServicios(getNameLaboratorio, categoriaLaboratorio)"
                    >
                      {{getNameLaboratorio}}
                    </button>
                    <button
                      v-if="isActiveEcografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-3'"
                      type="button"
                      :disabled="preCierre"
                      :class="getClassButtonEcografia"
                      @click="openModalServicios(getNameEcografia, categoriaEcografia)"
                    >
                        {{getNameEcografia}}
                    </button>
                    <button
                      v-if="isActiveRayosX"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-4'"
                      type="button"
                      :disabled="preCierre"
                      :class="getClassButtonRayosX"
                      @click="openModalServicios(getNameRayosX, categoriaRayosX)"
                    >
                      {{getNameRayosX}}
                    </button>
                    <button
                      v-if="isActiveTomografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-5'"
                      type="button"
                      :disabled="preCierre"
                      :class="getClassButtonTomografia"
                      @click="openModalServicios(getNameTomografia, categoriaTomografia)"
                    >
                      {{getNameTomografia}}
                    </button>
                    <button
                      v-if="isActiveMamografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-5'"
                      type="button"
                      :disabled="preCierre"
                      :class="getClassButtonMamografia"
                      @click="openModalServicios(getNameMamografia, categoriaMamografia)"
                    >
                      {{getNameMamografia}}
                    </button>
                    <button
                      v-if="isActiveArcoC"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonArcoC"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameArcoEnC, categoriaArcoC)"
                    >
                      {{getNameArcoEnC}}
                    </button>
                    <button
                      v-if="isActiveFisioterapia"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonFisioterapia"
                      :disabled="preCierre"
                      @click="openModalFisioterapia(getNameFisioterapia, categoriaFisioterapia)"
                    >
                        {{getNameFisioterapia}}
                    </button>
                    <button
                      v-if="isActiveTratamiento"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonTratamiento"
                      :disabled="preCierre"
                      @click="openModalFisioterapia(getNameTratamiento, categoriaTratam)"
                    >
                      {{getNameTratamiento}}
                    </button>
                    <button
                      type="button"
                      class="btn-sm text-white mr-2"
                      :class="nombreButtonActivo == 'evolucion_medica' ?
                        'btn btn-success': 'btn btn-secondary'"
                      @click="onClickButtonEvolucionMedica"
                    >
                      Evolución Médica
                    </button>
                    <the-modal-servicio-externo
                      :havePreCierre="preCierre"
                      :data="dataModalCXP"
                      v-on:addCuentaPorPagar="addCuentaPorPagar"
                      :activateButton="nombreButtonActivo == 'servicio_externo' ? true : false"
                      v-on:activateTheButton="activateTheButton"
                    />
                    <TheModalServicioInterno
                      :empresa_moneda="empresa_moneda"
                      :medic="medico.listaMedicos"
                      :servicios_internos="tipo_servicios_internos"
                      :havePreCierre="preCierre"
                      @addServiciosInternos="addServiciosInternos"
                      :activateButton="nombreButtonActivo == 'servicio_interno' ? true : false"
                      @activateTheButton="activateTheButton"
                    />
                    <TheModalEvolucionMedica
                      @addEvolucionMedica="addEvolucionMedica"/>
                  </div>
                </div>
              </div>
            </div>

            <!-- tabla consumo productos -->
            <div class="row mb-2" v-show="nombreButtonActivo=='productos'">
              <div class="col-sm-12 px-0">
                <table class="table table-striped mb-0 table-hover">
                  <thead>
                    <tr>
                      <th width="2%"  class="th-custom-color text-center">N°</th>
                      <th width="10%" class="th-custom-color text-center">Cantidad</th>
                      <th width="40%" class="th-custom-color text-center">Nombre</th>
                      <th width="10%" class="th-custom-color text-center">Importe</th>
                      <th width="20%" class="th-custom-color text-center">Concentrado</th>
                      <th width="10%" class="th-custom-color text-center">Via</th>
                      <th width="15%" class="th-custom-color text-center">Indicacion</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="productos_sp.length === 0">
                      <td colspan="7" class="text-center">
                        No hay consumo productos registrados
                      </td>
                    </tr>
                    <tr v-for="(item, index) in productos_sp" :key="index">
                      <td class="text-center">{{item.numero}}</td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnOrange': item.tipo_consumo === 1,
                          'btn btnTurquesa': item.tipo_consumo === 2,
                          'btn btnBlue': item.tipo_consumo === 3
                        }"
                        >
                          {{item.cantidad}}
                        </button>
                      </td>
                      <td class="text-center">{{ item.producto || item.medicamento }}</td>
                      <div v-if="item.tipo_consumo !== 3">
                        <td class="text-center">{{ item.total}}</td>
                      </div>
                      <div v-else>
                        <td class="text-center"></td>
                      </div>
                      <td class="text-center"></td>
                      <td class="text-center">{{item.via}}</td>
                      <td class="text-center">{{item.indicacion}}</td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarSalidaProducto(index)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="productos_sp.length > 0">
                  <div class="row  text-right">
                    <div class="col-sm-1 ml-auto th-custom-color">
                      <h4><strong>Total:</strong></h4>
                    </div>
                    <div class="col-sm-1 mr-3 th-custom-color">
                      <div class="div-input bg-main">{{this.totalImporte.toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- tabla servicios internos -->
            <div class="row mb-2" v-show="nombreButtonActivo=='servicio_interno'">
              <div class="col-sm-12 rounded th-custom-color text-black py-2">
                <div class="row">
                  <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                  <div class="col-sm-3 font-weight-bold">Fecha</div>
                  <div class="col-sm-6 font-weight-bold">Servicios</div>
                  <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                </div>
              </div>
              <div class="col-sm-12"
                :class="servicios.length > 2? 'tabla-scroll-vertical': ''">
                <template v-if="servicios.length > 0">
                  <div
                    class="row mb-2 border-light pt-2"
                    v-for="(item, index) in servicios" :key="item.id"
                    :class="index === 0 ? '': 'border-top'"
                  >
                    <div class="col-sm-1">
                      <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                        style="cursor: pointer"
                        v-if="!preCierre"
                        @click="eliminarServiciosInternos(index)"></i>
                        <i v-else
                        class="nav-icon i-Close-Window font-weight-bold"
                        style="cursor: pointer"
                        :title="messagePreCierre"
                        ></i>
                    </div>
                    <div class="col-sm-3">
                      {{item.venta_servicio_fecha}}
                      <br>
                      <b>User: </b> {{ item.usuario }}
                    </div>
                    <div class="col-sm-6">
                      {{item.tipo_servicio_nombre}}
                      <br>
                      <b>Glosa: </b> {{ item.textoglosa }}
                    </div>
                    <div class="col-sm-2 text-center">
                      {{item.hora}}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="row mb-2 border-light pt-2 border-top">
                    <div class="col-sm-12 text-center">
                      No hay servicios internos registrados
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- tabla servicios externos (cxp) -->
            <div class="row mb-2" v-show="nombreButtonActivo=='servicio_externo'">
              <div class="col-sm-12 rounded th-custom-color text-black py-2">
                <div class="row">
                  <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                  <div class="col-sm-3 font-weight-bold">Fecha</div>
                  <div class="col-sm-6 font-weight-bold">Servicios</div>
                  <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                </div>
              </div>
              <div class="col-sm-12"
                :class="cuentas_x_pagar.length > 2? 'tabla-scroll-vertical': ''">
                <template v-if="cuentas_x_pagar.length > 0">
                  <div class="row mb-2 border-light pt-2"
                    v-for="(item, index) in cuentas_x_pagar" :key="''+item.id + index"
                    :class="index === 0 ? '': 'border-top'">
                    <div class="col-sm-1">
                      <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                        v-if="!preCierre"
                        style="cursor: pointer"  @click="eliminarCuentaPorPagar(index)"></i>
                      <i v-else
                      class="nav-icon i-Close-Window font-weight-bold"
                      style="cursor: pointer"
                      :title="messagePreCierre"
                      ></i>
                    </div>
                    <div class="col-sm-3">
                      {{ getFechaFormat(item.fecha, 'DD/MM/YYYY')}} <br>
                      {{item.usuario}}
                    </div>
                    <div class="col-sm-6">
                      {{item.origen_type}} - Dr. {{item.medico.nombre}}
                      <strong>Desc.: </strong>
                      {{item.glosa  ? item.glosa : item.descripcion}}
                    </div>
                    <div class="col-sm-2 text-center">
                      {{ getFechaFormat(item.created_at, 'HH:mm')}}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="row mb-2 border-light pt-2 border-top">
                    <div class="col-sm-12 text-center">
                      No hay servicios externos registrados
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- tabla laboratorio, ecografia, rayos x, tomografia, arco c -->
            <div
              class="row mb-2"
              v-if="
                categoriaServicioComplId === categoriaLaboratorio
                || categoriaServicioComplId === categoriaEcografia
                || categoriaServicioComplId === categoriaRayosX
                || categoriaServicioComplId === categoriaTomografia
                || categoriaServicioComplId === categoriaMamografia
                || categoriaServicioComplId === categoriaArcoC
                  ">
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th width="5%" class="th-custom-color text-center">N°</th>
                      <th width="20%" class="th-custom-color text-center">Categoria</th>
                      <th width="10%" class="th-custom-color text-center">Cant.</th>
                      <th width="20%" class="th-custom-color text-center">Servicios</th>
                      <th width="15%" class="th-custom-color text-center">Lista</th>
                      <th width="25%" class="th-custom-color text-center">Descripcion</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-center">{{ servicio.categoria }}</td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnTurquesa': servicio.tipo_consumo_servicio === 1,
                          'btn btnBlue': servicio.tipo_consumo_servicio === 2
                        }"
                        >
                          {{servicio.cantidad}}
                        </button>
                      </td>
                      <td class="text-center">{{ servicio.nombre }}</td>
                      <td class="text-center">{{ servicio.precio }}</td>
                      <td class="text-center">
                        <input type="text"
                          v-model="servicio.descripcion"
                          class="form-control"
                          >
                      </td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarServicio(servicio)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- tabla para fisioterapia -->
            <div class="row mb-2"  v-if="categoriaServicioComplId === categoriaFisioterapia
            || categoriaServicioComplId === categoriaTratam"
            >
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th width="5%" class="th-custom-color text-center">N°</th>
                      <th width="20%" class="th-custom-color text-center">Servicio</th>
                      <th width="20%" class="th-custom-color text-center">N° de Session</th>
                      <th width="15%" class="th-custom-color text-center">Lista</th>
                      <th width="35%" class="th-custom-color text-center">Descripción</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
                      <td class="text-center">{{ index + 1 }}</td>
                      <td class="text-center">{{ servicio.categoria }}</td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnTurquesa': servicio.tipo_consumo_servicio === 1,
                          'btn btnBlue': servicio.tipo_consumo_servicio === 2
                        }"
                        >
                          {{servicio.session}}
                        </button>
                      </td>
                      <td class="text-center">{{ servicio.precio }}</td>
                      <td class="text-center">
                        <input type="text"
                          v-model="servicio.descripcion"
                          class="form-control"
                          >
                      </td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarServicio(servicio)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- tabla Evolución Médica -->
            <div class="row mb-2" v-show="nombreButtonActivo=='evolucion_medica'">
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr class="th-custom-color ">
                      <th class="py-2 text-center" width="5%">N°</th>
                      <th class="py-2 text-center" width="10%">Fecha</th>
                      <th class="py-2 text-center" width="10%">Hora</th>
                      <th class="py-2 text-center" width="24%">Descripción</th>
                      <th class="py-2 text-center" width="24%">Indicación</th>
                      <th class="py-2 text-center" width="24%">Médico</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="(evolucion, index) in evolucionMedicas" :key="index">
                      <td class="text-center"> {{ index + 1 }} </td>
                      <td class="text-center"> {{ evolucion.fecha }} </td>
                      <td class="text-center"> {{ evolucion.hora }} </td>
                      <td class="text-center"> {{ evolucion.descripcion_medico }} </td>
                      <td class="text-center"> {{ evolucion.indicacion_medico }} </td>
                      <td class="text-center"> {{ evolucion.medico }} </td>
                      <td class="text-center">
                        <i
                          class="nav-icon i-Close-Window font-weight-bold text-danger"
                          style="cursor: pointer"
                          @click="eliminarEvolucionMedica(evolucion)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <TheModalAutorizacionInternacion/>
    <TheModalServicios
    :titleModal="nombreButtonActivo"
    :isInternation="isInternation"
    @setTipoConsumoProducto="setTipoConsumoProducto"
    @addServiciosComplemetarios="addServiciosComplemetarios"
    :seguro="seguro.model"
    :moneda="moneda"
    />
    <TheModalFisioterapia
    :titleModal="nombreButtonActivo"
    @addServiciosComplemetarios="addServiciosComplemetarios"
    @setTipoConsumoProducto="setTipoConsumoProducto"
    :isInternation="isInternation"
    :seguro="seguro.model"
    :moneda="moneda"
    />
  </div>
</template>

<script>
import store from '@/store/index';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { util } from '@/plugins/util';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { CATEGORIA_SERVICIO_COMPL, TIPO_CONSUMO_PRODUCTO_SERVICIO } from '@/utils/globalConventions';
import ComboPaciente from '../components/ThePatient.vue';
import ModalPacienteNuevo from '../components/TheModalPacienteNuevo.vue';
import ModalPieza from '../components/TheModalPieza.vue';
import TheModalConsumoProductos from '../components/TheModalConsumoProductos.vue';
import TheModalServicioExterno from '../components/TheModalServicioExterno.vue';
import TheModalServicioInterno from '../components/TheModalServicioInterno.vue';
import TheModalPreCierre from '../components/TheModalPreCierre.vue';
import ModalCierre from '../components/TheModalCierre.vue';
import TheModalServicios from '../../asistenciaMedico/components/TheModalServicios.vue';
import TheModalFisioterapia from '../../asistenciaMedico/components/TheModalFisioterapia.vue';
import QuirofanoItem from '../components/QuirofanoItem.vue';
import TheModalCirugia from '../components/TheModalCirugia.vue';
import TheModalEvolucionMedica from '../components/TheModalEvolucionMedica.vue';
import TheModalInterConsulta from '../components/TheModalInterConsulta.vue';
import TheModalAutorizacionInternacion from '../components/TheModalAutorizacionInternacion.vue';

export default {
  name: 'create',
  components: {
    Multiselect2,
    DatePicker,
    ComboPaciente,
    ModalPacienteNuevo,
    ModalPieza,
    TheModalServicioExterno,
    TheModalConsumoProductos,
    TheModalServicioInterno,
    TheModalPreCierre,
    ModalCierre,
    TheModalServicios,
    TheModalFisioterapia,
    QuirofanoItem,
    TheModalCirugia,
    TheModalEvolucionMedica,
    TheModalInterConsulta,
    TheModalAutorizacionInternacion,
  },
  data() {
    return {
      isLoading: false,
      fecha: new Date(),
      pacienteSelected: null,
      // pacientes: [],
      productos_sp: [],
      salidaProductos: {
        moneda_id: null,
        cuenta_contable_id: null,
        total: 0,
        detalles: [],
      },
      servicios: [],
      fechaCierre: new Date(),
      medico: {
        listaMedicos: [],
        model: [],
      },
      seguro: {
        listaSeguros: [],
        model: null,
      },
      almacen: {
        listaAlmacenes: [],
        model: null,
      },
      categoriaTratamiento: {
        listaCategorias: [],
        model: null,
      },
      dataPaciente: {},
      showCortinaInformativa: false,
      edad: '',
      mes: '',

      // CXP
      moneda: {
        model: null,
        multiselect: [],
        nuevo_tipo_cambio: null,
        tc_secundario: null,
      },
      isInternation: true,
      cxp: {},
      dataModalCXP: {},
      cuentas_x_pagar: [],
      field: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'fecha', label: 'Fecha', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'tipo_servicio_nombre', label: 'Servicios', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '%' },
        },
        {
          key: 'venta_servicio_cantidad', label: 'Cantidad', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '%' },
        },
      ],
      // servicios_internos: [],
      preCierre: false,
      anticipo: 0,
      tipo_servicios_internos: [],
      preCierreData: {
        total_precierre: 0,
        saldo_precierre: 0,
        anticipo_precierre: 0,
        glosa_precierre: null,
        estado_precierre: false,
        custom_id: null,
        fecha: null,
        hora: null,
        usuario: null,
      },
      cierre: {
        fecha: null,
        hora: null,
        glosa: null,
        estado: false,
      },
      empresa_moneda: null,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      isDisableSeguro: store.getters['main/hasPermisionListarSeguro'],
      nombreButtonActivo: '',
      modeAddItem: false,
      cirugias: [
        {
          id: 1,
          modeAddCirugia: false,
        },
      ],
      labelTratamiento: 'label',
      listaClasificacionCIE: [],
      clasificacionCIE: null,
      diagnostico: '',
      categoriaServicioComplId: null,
      medicationsProvidesByPharmacy: null, // false es rechazado y true es confirmado, null es no selecciono nada
      parametroServicioComplementarioList: [],
      servicioComplementario: [],
      tipo_consumo_producto: null,
      listTempServiciosComplementarios: [],
      evolucionMedicas: [],
    };
  },
  async created() {
    this.getData();
    await this.getParametrosConsultaExterna();
    this.detectAndSetComponentActive();
  },
  beforeDestroy() {
    this.resetModalPieza();
  },
  methods: {
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    getData() {
      this.isLoading = true;
      axios.get('clinic/tratamientos/create')
        .then((response) => {
          const nombreParametroTratamiento = response.data.data.nombre_parametro_consulta_externa;
          this.labelTratamiento = nombreParametroTratamiento?.nombre_tratamiento_principal ?? '';
          this.medico.listaMedicos = response.data.data.medicos;
          this.seguro.listaSeguros = response.data.data.seguros;
          this.almacen.listaAlmacenes = response.data.data.almacens;
          this.almacen.model = response.data.data.almacens.length === 1
            ? response.data.data.almacens[0] : null;
          this.categoriaTratamiento.listaCategorias = response.data.data.categoria_tratamientos;
          this.cargarModalPieza(response.data.data.sala_camas);
          // carga datos Modal cxp
          this.moneda.multiselect = response.data.data.monedas;
          this.moneda.model = this.loadMonedaPrincipal(this.moneda.multiselect);
          this.dataModalCXP.medicos = response.data.data.medicos;
          // this.dataModalCXP.medicos = this.medico.listaMedicos;
          this.dataModalCXP.monedas = this.moneda;
          this.dataModalCXP.parametrosContables = response.data.data.pametrosContables;
          this.dataModalCXP.tipo_comprobantes = response.data.data.tipo_comprobante
            .filter((ele) => (ele.id === 1 || ele.id === 4));
          this.dataModalCXP.cuenta_credito_fiscal = response.data.data.cuenta_credito_fiscal;
          this.dataModalCXP.tipoFacturas = response.data.data.tipo_facturas;
          this.tipo_servicios_internos = response.data.data.tipo_servicios;
          this.empresa_moneda = response.data.data.monedas;
          this.listaClasificacionCIE = response.data.data.clasificacion_cies;
        })
        .catch((error) => {
          console.log(error);
          util.showNotify(error.response.data.message, 'error');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    async getParametrosConsultaExterna() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        const parametro2 = response.data.data.nombre_parametro_servicio_complementario;
        if (parametro2.length !== 0) {
          this.parametroServicioComplementarioList = parametro2;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    detectAndSetComponentActive() {
      if (this.isActiveFarmacia) {
        this.nombreButtonActivo = 'productos';
        return;
      }
      if (this.isActiveLaboratorio) {
        this.nombreButtonActivo = 'Laboratorio';
        return;
      }
      if (this.isActiveEcografia) {
        this.nombreButtonActivo = 'Ecografia';
        return;
      }
      if (this.isActiveRayosX) {
        this.nombreButtonActivo = 'Rayos_X';
        return;
      }
      if (this.isActiveTomografia) {
        this.nombreButtonActivo = 'Tomografia';
        return;
      }
      if (this.isActiveMamografia) {
        this.nombreButtonActivo = 'Mamografia';
        return;
      }
      if (this.isActiveArcoC) {
        this.nombreButtonActivo = 'arco_c';
        return;
      }
      if (this.isActiveFisioterapia) {
        this.nombreButtonActivo = 'Fisioterapia';
        return;
      }
      if (this.isActiveTratamiento) {
        this.nombreButtonActivo = 'Tratamiento';
        return;
      }
      this.nombreButtonActivo = '';
    },
    AddPaciente(paciente) {
      if (paciente) {
        this.dataPaciente = paciente.datosPaciente;
        this.edad = (this.dataPaciente.edad) ? `${this.dataPaciente.edad} años` : '0 años';
        this.mes = (this.dataPaciente.meses) ? `${this.dataPaciente.meses} mes` : '0 mes';
        this.showCortinaInformativa = true;
        this.pacienteSelected = paciente.pacienteSelect;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
      } else {
        this.edad = '';
        this.mes = '';
        this.showCortinaInformativa = false;
        this.pacienteSelected = '';
        this.dataPaciente = {};
      }
    },
    store() {
      if (this.validar()) {
        this.preCierreData.estado_precierre = this.preCierre;
        this.cargarDatosPreCierre();
        const DATA = {
          paciente_id: this.pacienteSelected.id,
          fecha: this.fecha,
          fecha_cierre: this.fechaCierre,
          almacen_id: (this.almacen.model) ? this.almacen.model.id : null,
          seguro_id: (this.seguro.model) ? this.seguro.model.id : '',
          tratamiento_medicos: this.medico.model,
          categoria_tratamientos: this.categoriaTratamiento.model,
          cuentas_x_pagar: (this.cuentas_x_pagar.length > 0) ? this.cuentasPorPagarSendData() : [],
          monedas: this.moneda.multiselect,
          consumo_productos: this.salidaProductosSendData(),
          sala_cama_id: this.getIDItemChecked,
          venta_servicios: this.clearParams(),
          preCierre: this.preCierreData,
          cierre: this.cierre,
          clasificacion_cie_id: this.clasificacionCIE?.id ?? null,
          nombre_diagnostico: this.diagnostico,
          detalles_cirugias: [],
          servicios_complementarios: this.servicioComplementario,
          evolucion_medica: this.evolucionMedicas,
        };
        axios.post('clinic/tratamientos/store', DATA)
          .then((response) => {
            util.showNotify(response.data.message, 'success');
            this.$router.push('/internacion');
          })
          .catch((error) => {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
              util.showNotify(error.response.data.message, 'error');
            } else {
              Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
              });
            }
          });
      }
    },
    clearParams() {
      this.servicios.forEach((e, index) => {
        delete this.servicios[index].customId;
        delete this.servicios[index].usuario;
        delete this.servicios[index].textoglosa;
        delete this.servicios[index].tipo_servicio_nombre;
      });
      return this.servicios;
    },
    validar() {
      let validate = true;
      if (!this.pacienteSelected) {
        util.showNotify('Seleccione un paciente', 'warn');
        validate = false;
      }
      if (!this.fecha) {
        util.showNotify('La fecha es un campo obligatorio', 'warn');
        validate = false;
      }
      if (!this.fechaCierre) {
        util.showNotify('La fecha de cierre es un campo obligatorio', 'warn');
        validate = false;
      }
      if ((this.productos_sp.length > 0 || this.cuentas_x_pagar.length > 0)
        && !this.almacen.model) {
        util.showNotify('Debe seleccionar un almacen. \n Si va registrar Consumo Productos o Servicios Externos', 'warn');
        validate = false;
      }
      if (this.medico.model.length === 0) {
        util.showNotify('Selecciones un medico', 'warn');
        validate = false;
      }
      if (!this.categoriaTratamiento.model) {
        util.showNotify('Seleccione un tratamiento', 'warn');
        validate = false;
      }
      if (!this.clasificacionCIE) {
        util.showNotify('Seleccione un Diagnóstico', 'warn');
        validate = false;
      }
      if (this.fecha && this.fechaCierre) {
        if (this.fecha.getTime() > this.fechaCierre.getTime()) {
          util.showNotify('La fecha de cierre debe ser mayor que la fecha inicio', 'error');
          validate = false;
        }
      }
      return validate;
    },
    addCuentaPorPagar(cuentaPorPagar) {
      const cxpObj = JSON.stringify(cuentaPorPagar);
      this.cxp = JSON.parse(cxpObj);
      this.cuentas_x_pagar.push(this.cxp);
    },
    loadMonedaPrincipal(monedas) {
      for (let i = 0; i < monedas.length; i += 1) {
        if (monedas[i].is_principal === 1) {
          return monedas[i];
        }
      }
      return null;
    },
    cuentasPorPagarSendData() {
      const cxps = [];
      this.cuentas_x_pagar.forEach((cxp) => {
        cxps.push({
          persona_id: cxp.medico.persona_id,
          t_comprobante_id: cxp.tipo_comprobante.id,
          t_comprobante_child: cxp.t_comprobante_child,
          pre_retencion_tipo_id: null,
          nuevo_tipo_cambio: cxp.nuevo_tipo_cambio,
          fecha: cxp.fecha,
          fecha_hora: this.getFechaFormat(cxp.created_at, 'YYYY-MM-DD HH:mm:ss'),
          moneda_id: cxp.moneda.id,
          cuenta_pasivo_id: cxp.cuenta_pasivo_id,
          cuenta_gasto_id: cxp.cuenta_gasto_id,
          descripcion: cxp.glosa === '' ? null : cxp.glosa,
          importe: cxp.importe,
          retencion: cxp.retencion,
          importe_neto: cxp.importe_neto,
          descuento: cxp.descuento,
          fecha_vencimiento: cxp.fecha_vencimiento,
          factura: (cxp.tipo_comprobante.id === 1) ? cxp.factura : null,
          importe_actual: cxp.importe_actual,
        });
      });
      return cxps;
    },
    setTipoConsumoProducto(tipo) {
      this.tipo_consumo_producto = tipo;
    },
    addServiciosComplemetarios(dtos) {
      const SERVICIOS_COMPLEMENTARIOS = dtos;
      SERVICIOS_COMPLEMENTARIOS.forEach((element, index) => {
        const OBJ = {
          id: 0,
          servicio_complementario_id: SERVICIOS_COMPLEMENTARIOS[index].servicio_complementario_id,
          nombre: SERVICIOS_COMPLEMENTARIOS[index].nombre,
          estado: SERVICIOS_COMPLEMENTARIOS[index].estado,
          cantidad: SERVICIOS_COMPLEMENTARIOS[index].cantidad,
          precio: SERVICIOS_COMPLEMENTARIOS[index].precio,
          descripcion: SERVICIOS_COMPLEMENTARIOS[index].descripcion,
          categoria_serv_compl_id: this.categoriaServicioComplId,
          numero: SERVICIOS_COMPLEMENTARIOS[index].numero,
          categoria: SERVICIOS_COMPLEMENTARIOS[index].categoria,
          key: SERVICIOS_COMPLEMENTARIOS[index].key,
          tipo_consumo_servicio: this.tipo_consumo_producto,
          session: SERVICIOS_COMPLEMENTARIOS[index].session,
          fecha_hora: SERVICIOS_COMPLEMENTARIOS[index].fecha_hora,
        };
        this.servicioComplementario.push(OBJ);
      });
      this.filterListaTempServicioComplementario();
    },
    eliminarCuentaPorPagar(index) {
      this.cuentas_x_pagar.splice(index, 1);
    },
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    addProducto(producto) {
      const productoObj = JSON.parse(JSON.stringify(producto));
      this.productos_sp = []; // Reiniciar la lista de productos
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < productoObj.length; index++) {
        let importe = 0;
        const ele = productoObj[index];
        if (ele.tipo_consumo === 1) {
          importe = (ele.cantidad * ele.costo_unitario).toFixed(2);
        } else if (ele.tipo_consumo === 2) {
          importe = (ele.cantidad * ele.producto_precio).toFixed(2);
        }
        // Añadir producto a la lista
        this.productos_sp.push({
          id: 1,
          fecha: ele.fecha,
          producto: ele.producto_nombre,
          glosa: ele.glosa,
          cantidad: ele.cantidad,
          usuario: ele.usuario,
          medicamento: ele.medicamento_nombre,
          tipo_consumo: ele.tipo_consumo,
          indicacion: ele.indicacion,
          via: ele.via,
          total: importe,
        });

        // Solo procesar detalles si no es tipo_consumo 3
        if (ele.tipo_consumo !== 3) {
          const moneda = this.moneda.multiselect.find((mo) => mo.id === ele.moneda_id);
          if (moneda && moneda.is_principal !== 1) {
            // eslint-disable-next-line operator-assignment
            ele.producto_precio = moneda.tc * ele.producto_precio;
          }
          ele.total_venta = ele.producto_precio * ele.cantidad;
        }
        this.salidaProductos.detalles = productoObj;
      }
    },
    eliminarSalidaProducto(index) {
      this.productos_sp.splice(index, 1);
      this.salidaProductos.detalles.splice(index, 1);
      this.$emit('delDetalle', index);
    },
    salidaProductosSendData() {
      this.salidaProductos.moneda_id = this.moneda.model.id;
      this.salidaProductos.cuenta_contable_id = this.dataModalCXP
        .parametrosContables.cuenta_debe_sp;
      // const DETALLES = this.salidaProductos.detalles;
      let total = 0;
      for (let index = 0; index < this.salidaProductos.detalles.length;) {
        const ele = this.salidaProductos.detalles[index];
        index += 1;
        ele.fecha = this.getFechaFormat(ele.fecha, 'YYYY-MM-DD HH:mm:ss');
        ele.fecha_hora = moment().format('YYYY-MM-DD HH:mm:ss');
        total += parseFloat(ele.total);
      }
      this.salidaProductos.total = total;
      return this.salidaProductos;
    },
    openModalPieza() {
      this.$bvModal.show('modal-pieza');
    },
    openModalAutorizacionInternacion() {
      this.medicationsProvidesByPharmacy = true;
      this.$bvModal.show('ModalAutorizacionInternacion');
    },
    openModalCierre() {
      this.$bvModal.show('modal-cierre');
    },
    ...mapMutations('modalPieza', [
      'cargarModalPieza',
    ]),
    ...mapActions('modalPieza', [
      'resetModalPieza',
    ]),
    addServiciosInternos(data) {
      const service = data.servicios;
      this.servicios.push(service);
    },
    eliminarServiciosInternos(index) {
      this.servicios.splice(index, 1);
    },
    agregarPrecierre(data) {
      this.preCierreData.total_precierre = data.total;
      this.preCierreData.saldo_precierre = data.saldo;
      this.preCierreData.anticipo_precierre = data.anticipo;
      this.preCierreData.glosa_precierre = data.glosa;
      this.preCierreData.estado_precierre = data.estado_pre_cierre;
      this.preCierreData.fecha = data.fecha;
      this.preCierreData.hora = data.hora;
      this.preCierre = data.isPrecierre;
    },
    limpiarPreCierre() {
      this.preCierreData.total_precierre = 0;
      // this.preCierreData.anticipo_precierre = 0;
      this.preCierreData.saldo_precierre = 0;
      this.preCierreData.glosa_precierre = null;
      this.preCierreData.estado_precierre = false;
      this.preCierreData.custom_id = new Date().getTime();
      // marcar el estado del cierre como false;
      this.cierre.estado = false;
      this.cierre.hora = null;
      this.cierre.glosa = null;
    },
    agregarCierre(data) {
      this.cierre.fecha = data.fecha;
      this.cierre.hora = data.hora;
      this.cierre.glosa = data.glosa;
      this.cierre.estado = data.estado;
    },
    cargarDatosPreCierre() {
      const TOTAL = parseFloat(this.serviciosInternos) + parseFloat(this.centasXPagar)
      + parseFloat(this.consumoProducto) + parseFloat(this.totalServicioComplementarios);
      const SALDO = parseFloat(TOTAL) - parseFloat(this.preCierreData.anticipo_precierre);
      this.preCierreData.total_precierre = TOTAL;
      this.preCierreData.saldo_precierre = SALDO;
    },
    activateTheButton(data) {
      this.nombreButtonActivo = data;
      this.categoriaServicioComplId = null;
      this.$bvModal.hide('theModalProductoServicios');
      this.$bvModal.hide('TheModalFisioterapia');
    },
    async openModalServicios(titleModal, catServicioCompId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = catServicioCompId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId,
        seguro: this.seguro.model,
      };
      await this.getDataServicioComplementario(DTO);
      this.isInternation = true;
      // ocultar el modal consumoProducto o Farmacia
      this.$refs.theModalConsumoProducto.onCloseModal();
      this.$bvModal.show('theModalProductoServicios');
    },
    async openModalFisioterapia(titleModal, categoriaId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = categoriaId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId: categoriaId,
        seguro: this.seguro.model,
      };
      await this.getDataServicioComplementario(DTO);
      // ocultar el modal consumoProducto o Farmacia
      this.$refs.theModalConsumoProducto.onCloseModal();
      this.$bvModal.show('TheModalFisioterapia');
    },
    onClickButtonEvolucionMedica() {
      this.nombreButtonActivo = 'evolucion_medica';
      this.categoriaServicioComplId = null;
      this.$bvModal.hide('theModalProductoServicios');
      this.$bvModal.hide('TheModalFisioterapia');
      this.openModalEvolucionMedica();
    },
    openModalEvolucionMedica() {
      this.$bvModal.show('TheModalEvolucionMedica');
    },
    openModalCirugia(toAddNewItem) {
      this.modeAddItem = toAddNewItem;
      this.$bvModal.show('modal-cirugia');
    },
    openModalInterConsulta() {
      this.$bvModal.show('modal-interConsulta');
    },
    addItemCirugia(data) {
      const item = {
        id: data.id,
        modeAddCirugia: false,
      };
      this.cirugias.push(item);
    },
    eliminarServicio(item) {
      const ID = item.id;
      if (ID > 0) {
        const INDEX = this.servicioComplementario.findIndex((el) => (
          el.id === item.id));
        this.servicioComplementario[INDEX].estado = false;
      } else {
        const INDEX = this.servicioComplementario.findIndex((el) => (
          el.key === item.key));
        this.servicioComplementario.splice(INDEX, 1);
      }
      this.filterListaTempServicioComplementario();
    },
    ...mapActions('theModalServicios', [
      'resetDataTheModaServios',
      'getDataServicioComplementario',
    ]),
    saveData() {
      //
    },
    filterListaTempServicioComplementario() {
      switch (this.categoriaServicioComplId) {
        case this.categoriaLaboratorio:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaLaboratorio && obj.estado === true
          ));
          break;
        case this.categoriaEcografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaEcografia && obj.estado === true
          ));
          break;
        case this.categoriaRayosX:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaRayosX && obj.estado === true
          ));
          break;
        case this.categoriaTomografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTomografia && obj.estado === true
          ));
          break;
        case this.categoriaMamografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaMamografia && obj.estado === true
          ));
          break;
        case this.categoriaArcoC:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaArcoC && obj.estado === true
          ));
          break;
        case this.categoriaFisioterapia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaFisioterapia && obj.estado === true
          ));
          break;
        case this.categoriaTratam:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTratam && obj.estado === true
          ));
          break;
        default:
          break;
      }
    },
    goToHistorialClinico() {
      const paciente = this.pacienteSelected;
      if (paciente) {
        this.$router.push({
          name: 'historial-clinico',
          params: { id: paciente.id },
        });
      } else {
        util.showNotify('Debe Seleccionar un Paciente', 'warn');
      }
    },
    addEvolucionMedica(dto) {
      const OBJECT = {
        id: dto.id,
        descripcion_medico: dto.descripcion,
        indicacion_medico: dto.indicacion,
        medico: dto.userName,
        estado: dto.estado,
        fecha: dto.fecha,
        hora: dto.hora,
      };
      this.evolucionMedicas.push(OBJECT);
    },
    eliminarEvolucionMedica(item) {
      const ID = item.id;
      if (ID > 0) {
        const INDEX = this.evolucionMedicas.findIndex((el) => (
          el.id === item.id));
        this.evolucionMedicas[INDEX].estado = false;
      } else {
        const INDEX = this.evolucionMedicas.findIndex((el) => (
          el.key === item.key));
        this.evolucionMedicas.splice(INDEX, 1);
      }
    },
    formattedDate(fecha) {
      const date = new Date(fecha);
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  },
  computed: {
    ...mapGetters('modalPieza', [
      'getIDItemChecked',
      'getCamaItemChecked',
      'getPisoSalaItemChecked',
    ]),
    categoriaEcografia() {
      return CATEGORIA_SERVICIO_COMPL.ECOGRAFIA;
    },
    categoriaLaboratorio() {
      return CATEGORIA_SERVICIO_COMPL.LABORATORIO;
    },
    categoriaRayosX() {
      return CATEGORIA_SERVICIO_COMPL.RAYOS_X;
    },
    categoriaTomografia() {
      return CATEGORIA_SERVICIO_COMPL.TOMOGRAFIA;
    },
    categoriaMamografia() {
      return CATEGORIA_SERVICIO_COMPL.MAMOGRAFIA;
    },
    categoriaArcoC() {
      return CATEGORIA_SERVICIO_COMPL.ARCO_C;
    },
    categoriaFisioterapia() {
      return CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA;
    },
    categoriaFarmacia() {
      return CATEGORIA_SERVICIO_COMPL.FARMACIA;
    },
    categoriaTratam() {
      return CATEGORIA_SERVICIO_COMPL.TRATAMIENTO;
    },
    isDisabledAlmacen() {
      return this.salidaProductos.detalles.length > 0;
    },
    getClassButtonFarmacia() {
      let classToApply = 'btn-light';
      /* if (this.consulta === null) {
        return classToApply;
      } */
      if (this.nombreButtonActivo === this.getNameFarmacia) {
        classToApply = 'btn-success';
      } else {
        classToApply = 'btn-secondary';
      }
      return classToApply;
    },
    getClassButtonLaboratorio() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameLaboratorio) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonEcografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameEcografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonRayosX() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameRayosX) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTomografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTomografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonMamografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameMamografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonArcoC() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameArcoEnC) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonFisioterapia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameFisioterapia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTratamiento() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTratamiento) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    ...mapState('main', [
      'user',
    ]),
    serviciosInternos() {
      let total = 0;
      this.servicios.forEach((item) => {
        total += parseFloat(item.importe);
      });
      return total;
    },
    centasXPagar() {
      let total = 0;
      this.cuentas_x_pagar.forEach((item) => {
        const tipoMoneda = this.moneda.multiselect.find(
          (el) => el.id === item.moneda_id,
        );
        if (tipoMoneda.is_principal === 0) {
          total += parseFloat(item.importe) * parseFloat(tipoMoneda.tc);
        } else {
          total += parseFloat(item.importe);
        }
      });
      return total;
    },
    consumoProducto() {
      let total = 0;
      this.salidaProductos.detalles.forEach((item) => {
        if (item.tipo_consumo === 2) {
          total += parseFloat(item.total_venta);
        }
      });
      return total;
    },
    getNameFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.nombre;
      }
      return 'Farmacia';
    },
    getNameLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.nombre;
      }
      return 'Laboratorio';
    },
    getNameEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.nombre;
      }
      return 'Ecografia';
    },
    getNameRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.nombre;
      }
      return 'Rayos X';
    },
    getNameTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.nombre;
      }
      return 'Tomografia';
    },
    getNameMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.nombre;
      }
      return 'Mamografia';
    },
    getNameArcoEnC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.nombre;
      }
      return 'Arco en C';
    },
    getNameFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.nombre;
      }
      return 'Fisioterapia';
    },
    getNameTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.nombre;
      }
      return 'Tratamiento';
    },
    getItemFarmacia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 8) ?? null;
        return item;
      }
      return null;
    },
    getItemLaboratorio() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 9) ?? null;
        return item;
      }
      return null;
    },
    getItemEcografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 1) ?? null;
        return item;
      }
      return null;
    },
    getItemRayosX() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 2) ?? null;
        return item;
      }
      return null;
    },
    getItemTomografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 3) ?? null;
        return item;
      }
      return null;
    },
    getItemMamografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 4) ?? null;
        return item;
      }
      return null;
    },
    getItemArcoC() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 5) ?? null;
        return item;
      }
      return null;
    },
    getItemFisioterapia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemTratamiento() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.TRATAMIENTO))
          ?? null;
        return item;
      }
      return null;
    },
    isActiveFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.estado;
      }
      return false;
    },
    isActiveLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.estado;
      }
      return false;
    },
    isActiveEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.estado;
      }
      return false;
    },
    isActiveRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.estado;
      }
      return false;
    },
    isActiveTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.estado;
      }
      return false;
    },
    isActiveMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.estado;
      }
      return false;
    },
    isActiveArcoC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.estado;
      }
      return false;
    },
    isActiveFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.estado;
      }
      return false;
    },
    isActiveTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.estado;
      }
      return false;
    },
    totalServicioComplementarios() {
      let total = 0;
      this.servicioComplementario.forEach((item) => {
        if (item.tipo_consumo_servicio === TIPO_CONSUMO_PRODUCTO_SERVICIO.VENTA) {
          total += parseFloat(item.precio);
        }
      });
      return total;
    },
    totalImporte() {
      return this.productos_sp.reduce((total, producto) => {
        // Asegúrate de que producto.total sea un número válido y sin formato
        const totalProducto = parseFloat(producto.total) || 0;
        return total + totalProducto;
      }, 0);
    },
  },
  watch: {
    // pacienteSelected() {
    //   this.$refs.theModalPreCierre.restaurar();
    //   this.preCierre = false;
    // },

    'dataPaciente.cobertura': {
      handler(newSeguro) {
        if (newSeguro) {
          const seguroEnLista = this.seguro.listaSeguros.find((seguro) => seguro.nombre_comercial === newSeguro);
          if (seguroEnLista) {
            this.seguro.model = seguroEnLista;
          } else {
            this.seguro.model = null;
          }
        } else {
          this.seguro.model = null;
        }
      },
      deep: true, // Observa cambios profundos dentro de dataPaciente.cobertura
    },
    categoriaServicioComplId(new1) {
      if (new1) {
        this.filterListaTempServicioComplementario();
      }
    },
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.text-skyblue
{
  color: #61c6f2;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.tabla-scroll-vertical {
    overflow-y: scroll;
    height: 180px;
}
.btnBlue {
  color: white;
  background-color: #47404f;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
.bg-abierto {
  background: #f8cb9e;
}
.custom-small-text {
  font-size: 8px;
}
</style>
