<template>
  <div style="display: inline">
    <button
      v-if="editCxPIndex < 0"
      class="mr-2"
      :class="activateButton ? 'btn btn-success' : 'btn btn-secondary'"
      :disabled="havePreCierre || true"
      @click="showModalEvent"
      :title="havePreCierre ? messagePreCierre : ''"
      type="button"> Servicios (Ex) </button>
    <i v-else-if="!havePreCierre"
      class="nav-icon i-Pen-5 text-success font-weight-bold"
      style="cursor: pointer"
      @click="showModalEvent" >
    </i>
    <i v-else
      class="nav-icon i-Pen-5 font-weight-bold"
      style="cursor: pointer"
      :title="messagePreCierre">
    </i>
    <b-modal v-model="showModal"
      size="lg"
      title="Cuenta Por Pagar (CXP)"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close >
      <div class="row">
        <div class="col-sm-7">
          <!-- Moneda: -->
          <div class="form-group row">
            <label for="moneda_id"
              class="col-sm-3 col-form-label text-right" >Moneda:</label >
            <div class="col-sm-5">
              <multiselect2
                v-model="cuentaPorPagar.moneda"
                track-by="name"
                label="name"
                placeholder="Seleccione"
                :options="monedas"
                @select="selectMoneda"
                :allow-empty="false"
                :searchable="false"
                :disabled="isDisableField">
              </multiselect2>
            </div>
            <div class="col-sm-3">
              <input type="text"
                :disabled="isDisableFieldTC"
                class="form-control"
                placeholder="TC"
                v-model="cuentaPorPagar.nuevo_tipo_cambio"/>
            </div>
          </div>
          <!-- Medico: -->
          <div class="form-group row">
            <label for="medico" class="col-sm-4 col-form-label text-right">Medico:</label>
            <div class="col-sm-7">
              <multiselect2
                v-model="cuentaPorPagar.medico"
                :options="medicos"
                placeholder="Seleccione Medico"
                open-direction="bottom"
                :internal-search="false"
                label="nombre" track-by="nombre">
              </multiselect2>
            </div>
          </div>
          <div class="form-group row mt-2">
            <div class="col-sm-5">
                <multiselect2
                    v-model="cuentaPorPagar.tipo_comprobante"
                    track-by="nombre"
                    label="nombre"
                    placeholder="Tipo de Documento"
                    @select="selectTipoComprobante"
                    :options="tipo_comprobantes"
                    :allow-empty="false"
                    select-label="" deselect-label="X"
                    :searchable="false">
                </multiselect2>
            </div>
            <div class="col-sm-3">
                <input v-show="cuentaPorPagar.tipo_comprobante"
                  class="form-control" type="text"
                  v-model="cuentaPorPagar.t_comprobante_child" :readonly="t_comprobante_child"/>
            </div>
              <div class="col-sm-3" v-if="editCxPIndex < 0">
                <factura v-show="cuentaPorPagar.tipo_comprobante
                  && cuentaPorPagar.tipo_comprobante.id == 1"
                    :subtotal="Number(cuentaPorPagar.importe)"
                    :tipos_factura="factura.tipos_factura"
                    :fecha="cuentaPorPagar.fecha"
                    :descuento="cuentaPorPagar.descuento"
                    :cuentaImpuesto="factura.cuentaImpuesto"
                    :nit="cuentaPorPagar.medico ? cuentaPorPagar.medico.ci_nit : null"
                    :numeroComprobante="cuentaPorPagar.t_comprobante_child"
                    @factura-saved="factura.saved = $event"
                    :is-in-moneda-principal="isInMonedaPrincipal"
                    :tipo-cambio="cuentaPorPagar.nuevo_tipo_cambio"
                    ref="factura" />
            </div>
            <div class="col-sm-3" v-else-if="factura.data">
                <factura v-show="cuentaPorPagar.tipo_comprobante
                  && cuentaPorPagar.tipo_comprobante.id == 1"
                    :subtotal="Number(cuentaPorPagar.importe)"
                    :tipos_factura="factura.tipos_factura"
                    :fecha="cuentaPorPagar.fecha"
                    :descuento="cuentaPorPagar.descuento"
                    :cuentaImpuesto="factura.cuentaImpuesto"
                    :load="factura.data"
                    @factura-saved="factura.saved = $event"
                    :is-in-moneda-principal="isInMonedaPrincipal"
                    :tipo-cambio="cuentaPorPagar.nuevo_tipo_cambio"
                    ref="factura" />
            </div>
            <div class="col-sm-3" v-else>
                <factura v-show="cuentaPorPagar.tipo_comprobante
                  && cuentaPorPagar.tipo_comprobante.id == 1"
                    :subtotal="Number(cuentaPorPagar.importe)"
                    :tipos_factura="factura.tipos_factura"
                    :fecha="cuentaPorPagar.fecha"
                    :descuento="cuentaPorPagar.descuento"
                    :cuentaImpuesto="factura.cuentaImpuesto"
                    :nit="cuentaPorPagar.proveedor ? cuentaPorPagar.proveedor.ci_nit : null"
                    :numeroComprobante="cuentaPorPagar.t_comprobante_child"
                    @factura-saved="factura.saved = $event"
                    :is-in-moneda-principal="isInMonedaPrincipal"
                    :tipo-cambio="cuentaPorPagar.nuevo_tipo_cambio"
                    ref="factura"/>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="form-group row">
            <label for="fecha" class="col-sm-3 col-form-label text-right">Fecha:</label>
            <div class="col-sm-6 offset-sm-2">
              <date-picker
                id="fecha"
                lang="es"
                class="col-sm-12 px-0"
                format="DD/MM/YYYY"
                type="date"
                value-type="date"
                v-model="cuentaPorPagar.fecha"
                :disabled="isDisableField"
                @change="actualizarFechaVencimientoEnd"
                width="100%">
              </date-picker>
            </div>
          </div>
          <div class="form-group row">
            <label for="fecha_v" class="col-sm-3 col-form-label text-right"
              >F/Vencimiento:
            </label>
            <div class="col-sm-6 offset-sm-2">
              <date-picker
                id="fecha_v"
                v-model="cuentaPorPagar.fecha_vencimiento"
                class="col-sm-12 px-0"
                lang="es"
                format="DD/MM/YYYY"
                type="date"
                value-type="date"
                @change="validateFechaVencimiento"
                width="100%">
              </date-picker>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <div class="form-group row">
              <label for="nit" class="col-sm-12 col-form-label text-left">Descripcion: </label>
            <div class="col-sm-12">
                <b-form-textarea
                    class="form-control no-resize"
                    v-model="cuentaPorPagar.glosa"
                    rows="4"
                    max-rows="4"
                    style="overflow-y: auto;"
                ></b-form-textarea>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-sm-3">
                <label
                  for="cuenta_pasivo"
                  class="col-sm-12 col-form-label text-left"
                  >Cantidad:</label>
                <div class="col-sm-12">
                  <vue-numeric class="form-control"
                    name="cantidad"
                    v-model="cantidad"
                    separator=","
                    :precision="0"
                    placeholder=""/>
                </div>
            </div>

            <div class="col-sm-3 p-0">
              <label for="nit" class="col-sm-12 col-form-label text-left">Servicio:</label>
              <div class="col-sm-12 p-0">
                <input type="text" class="form-control">
              </div>
            </div>

            <div class="col-sm-4 p-0">
              <label for="nit" class="col-sm-12 col-form-label text-left">Comentario:</label>
              <div class="col-sm-12 pl-2">
                <input type="text" class="form-control">
              </div>
            </div>

            <div class="col-sm-2 p-0">
              <div class="col-sm-12 mt-4"></div>
              <div class="col-sm-12 pl-0 mt-2">
                <button class="_btn btn btn-success">Agregar</button>
              </div>
            </div>
          </div> -->
        </div>

        <div class="col-sm-4">
          <div class="mr-2"
            style="padding: 5px 0px 1px 0px; background-color: #fef7f1">
            <div class="form-group row">
              <label for="importe" class="col-sm-6 col-form-label text-right">Importe:</label>
              <div class="col-sm-5">
                  <!-- @change.native="actualizarImporteNeto" -->
                  <vue-numeric class="form-control"
                    name="importe"
                    v-model="cuentaPorPagar.importe"
                    separator=","
                    :precision="2"
                    placeholder=""/>
              </div>
            </div>
            <div class="form-group row">
              <label for="retencion" class="col-sm-6 col-form-label text-right"
                >Retencion:
              </label>
              <div class="col-sm-5">
                <vue-numeric class="form-control"
                    v-model="cuentaPorPagar.retencion"
                    name="retencion"
                    separator=","
                    :precision="2"
                    placeholder=""
                  />
              </div>
            </div>
            <div class="form-group row">
              <label for="descuento" class="col-sm-6 col-form-label text-right"
                >Descuento:
              </label>
              <div class="col-sm-5">
                <vue-numeric class="form-control"
                    v-model="cuentaPorPagar.descuento"
                    name="descuento"
                    separator=","
                    :precision="2"
                    placeholder=""/>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="importe_neto"
                class="col-sm-6 col-form-label text-right"
                >Importe Neto:
              </label>
              <div class="col-sm-5">
                <vue-numeric class="form-control"
                    v-model="cuentaPorPagar.importe_neto"
                    name="importe_neto"
                    separator=","
                    :precision="2"
                    disabled="true"
                    placeholder=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12 text-right pr-4">
          <button
            type="button"
            class="btn btn-secondary mr-2"
            @click="showModal = false">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="saveCuentaPorPagar">
            Agregar
          </button>
        </div>
      </div>
    </b-modal>
</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Multiselect2 from '@/components/util/vue-multiselect2';
// import moment from 'moment';
import { mapState } from 'vuex';
import VueNumeric from 'vue-numeric';
import factura from '@/components/common/factura.vue';
import { util } from '../../../plugins/util';

export default {
  name: 'TheModalServicioExterno',
  props: {
    data: {
      type: Object,
      required: true,
      default: Object,
    },
    editCxPIndex: {
      type: Number,
      default: -1,
    },
    havePreCierre: {
      type: Boolean,
      required: true,
      default: false,
    },
    activateButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      tipoPersona: null,
      tipoPersonas: [],
      tipo_comprobantes: [],
      t_comprobante_child: false,
      isEditando: false,
      cuentas_x_pagar: [],
      factura: {
        saved: false,
        data: {},
        tipos_factura: [],
        tipos_retencion: [],
        cuentaImpuesto: null,
      },
      monedas: [],
      isDisableField: false,
      isDisableFieldTC: true,
      saved: false,
      medicos: [],
      // Cuenta Por Pagar
      cuentaPorPagar: {
        id: 0,
        numero: '',
        origen_type: 'Local',
        medico: null,
        tipo_comprobante: null,
        t_comprobante_child: null,
        nuevo_tipo_cambio: null,
        fecha: new Date(),
        moneda: null,
        cuenta_pasivo_id: null,
        cuenta_gasto_id: null,
        glosa: '',
        importe: 0,
        retencion: 0,
        importe_neto: 0,
        descuento: 0,
        fecha_vencimiento: null,
        factura: null,
        index: -1,
        fecha_show: '',
        usuario: '',
        created_at: null,
        importe_actual: 0,
      },
      moneda: null,
      nuevo_tipo_cambio: null,
      cantidad: 1,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
    };
  },
  methods: {
    cargarData() {
      // console.log('data:', this.data);
      this.medicos = this.data.medicos;
      // this.cuentaPorPagar.medico = this.data.medico;
      this.monedas = this.data.monedas.multiselect;
      this.factura.tipos_factura = this.data.tipoFacturas;
      this.tipo_comprobantes = this.data.tipo_comprobantes;
      this.cuentaPorPagar.nuevo_tipo_cambio = this.data.monedas.nuevo_tipo_cambio;
      if (this.cuentaPorPagar.nuevo_tipo_cambio) {
        this.data.monedas.model.tc = this.cuentaPorPagar.nuevo_tipo_cambio;
      }
      this.cuentaPorPagar.moneda = this.data.monedas.model;
      this.factura.tipos_factura = this.data.tipoFacturas;
      this.factura.cuentaImpuesto = this.data.cuenta_credito_fiscal;
      this.cuentas_x_pagar = (this.editCxPIndex >= 0) ? this.data.cuentas_x_pagar : [];
      if (this.editCxPIndex >= 0) {
        this.isEditando = true;
        const cxpEdit = this.cuentas_x_pagar[this.editCxPIndex];
        this.cargarFacturaEdit(this.cloneObject(cxpEdit));
        this.cargarEditForm(this.cloneObject(cxpEdit));
        this.isDisableField = true;
      }
    },
    cargarEditForm(cxp) {
      this.cuentaPorPagar.id = cxp.id;
      this.cuentaPorPagar.numero = cxp.numero;
      this.cuentaPorPagar.origen_type = cxp.origen_type;
      this.cuentaPorPagar.medico = this.medicos.find(
        (ele) => ele.persona_id === cxp.persona_id,
      );
      this.cuentaPorPagar.tipo_comprobante = this.tipo_comprobantes.find(
        (ele) => ele.id === cxp.tipo_comprobante_id,
      );
      this.cuentaPorPagar.t_comprobante_child = cxp.comprobante;
      this.cuentaPorPagar.moneda = this.monedas.find(
        (ele) => ele.id === cxp.moneda_id,
      );
      this.cuentaPorPagar.factura = cxp.factura;
      this.cuentaPorPagar.importe = cxp.importe;
      this.cuentaPorPagar.descuento = cxp.descuento;
      this.cuentaPorPagar.importe_neto = this.cuentaPorPagar.importe - cxp.descuento;
      this.cuentaPorPagar.retencion = cxp.retencion;
      this.cuentaPorPagar.cuenta_pasivo_id = cxp.cuenta_contable_id;
      this.cuentaPorPagar.cuenta_gasto_id = cxp.cuenta_contable_gasto_id;
      this.cuentaPorPagar.usuario = cxp.usuario;
      this.cuentaPorPagar.created_at = cxp.created_at;
      this.cuentaPorPagar.fecha = new Date(cxp.fecha_deuda);
      if (cxp.fecha_vencimiento) {
        this.cuentaPorPagar.fecha_vencimiento = new Date(cxp.fecha_vencimiento);
      } else {
        this.actualizarFechaVencimientoEnd();
      }
      this.cuentaPorPagar.glosa = cxp.descripcion;
      this.cuentaPorPagar.index = this.editCxPIndex;
      this.cuentaPorPagar.importe_actual = cxp.importe_actual;
    },
    cargarFacturaEdit(cxp) {
      let facturaEditObject;
      if (!cxp.factura) {
        this.factura.data = null;
      } else {
        facturaEditObject = this.cloneObject(cxp.factura);
        if (cxp.factura.id) {
          this.factura.data = facturaEditObject;
        } else {
          this.factura.data = {
            numero: facturaEditObject.numero,
            numero_autorizacion: facturaEditObject.numero_autorizacion,
            nombre: facturaEditObject.nombre,
            nit: facturaEditObject.nit,
            fecha: facturaEditObject.fecha,
            tipo_numero_id: facturaEditObject.tipo_numero_factura_id,
            tipo_compra_id: facturaEditObject.tipo_factura_compra_id,
            total: facturaEditObject.importe_total,
            sujeto_cf: facturaEditObject.sujeto_cf,
            descuento: facturaEditObject.descuento,
            impuesto: facturaEditObject.impuesto,
          };
        }
      }
    },
    showModalEvent() {
      const modalBloqueado = false;
      if (modalBloqueado) {
        return;
      }
      this.cargarData();
      this.limpiarCuentaPorPagar();
      this.showModal = true;
      this.$emit('activateTheButton', 'servicio_externo');
    },
    saveCuentaPorPagar() {
      if (this.validate()) {
        if (this.cuentaPorPagar.tipo_comprobante.id === 1) {
          this.cuentaPorPagar.factura = this.$refs.factura
            ? this.$refs.factura.getData()
            : null;
          this.cuentaPorPagar.t_comprobante_child = this.cuentaPorPagar.factura.numero;
        }
        this.showModal = false;
        this.isDisableField = false;
        this.cuentaPorPagar.created_at = (this.cuentaPorPagar.created_at === null) ? new Date()
          : this.cuentaPorPagar.created_at;
        this.cuentaPorPagar.usuario = (this.cuentaPorPagar.usuario === '') ? this.user.name : this.cuentaPorPagar.usuario;
        this.cuentaPorPagar.moneda_id = this.cuentaPorPagar.moneda.id;
        this.$emit('addCuentaPorPagar', this.cuentaPorPagar);
      }
    },
    actualizarFechaVencimientoEnd() {
      const lastDayOfMonth = new Date(this.cuentaPorPagar.fecha.getFullYear(),
        this.cuentaPorPagar.fecha.getMonth() + 1, 0);
      this.cuentaPorPagar.fecha_vencimiento = lastDayOfMonth;
    },
    validate() {
      let valido = true;
      if (this.cuentaPorPagar.medico == null) {
        util.showNotify('Datos Incompletos \n Debe ingresar un medico!', 'warn');
        valido = false;
      }
      if (this.cuentaPorPagar.tipo_comprobante == null) {
        util.showNotify('Datos Incompletos \n Debe ingresar el Tipo de Comprobante!', 'warn');
        valido = false;
      } else if (this.cuentaPorPagar.tipo_comprobante.id === 1) {
        if (!this.$refs.factura.validar()) {
          util.showNotify('Datos Incompletos \n Ingrese datos de factura!', 'warn');
          valido = false;
        }
        if (!this.factura.saved) {
          util.showNotify('Datos Incompletos \n Ingrese datos de factura!', 'warn');
          valido = false;
        }
      }
      if (this.cuentaPorPagar.moneda == null) {
        util.showNotify('Datos Incompletos \n Debe ingresar un Moneda!', 'warn');
        valido = false;
      }
      if (this.cuentaPorPagar.importe <= 0) {
        util.showNotify('Datos Incompletos \n Debe ingresar un Importe valido!', 'warn');
        valido = false;
      }
      if (this.cuentaPorPagar.fecha == null) {
        util.showNotify('Datos Incompletos \n Debe ingresar un Fecha!', 'warn');
        valido = false;
      }
      return valido;
    },
    limpiarCuentaPorPagar() {
      if (this.editCxPIndex < 0) {
        this.cuentaPorPagar.medico = null;
        this.cuentaPorPagar.tipo_comprobante = null;
        this.cuentaPorPagar.t_comprobante_child = null;
        // this.cuentaPorPagar.nuevo_tipo_cambio = null;
        this.cuentaPorPagar.fecha = new Date();
        // this.cuentaPorPagar.moneda = this.monedas[0];
        this.cuentaPorPagar.glosa = '';
        this.cuentaPorPagar.importe = 0;
        this.cuentaPorPagar.retencion = 0;
        this.cuentaPorPagar.importe_neto = 0;
        this.cuentaPorPagar.descuento = 0;
        this.actualizarFechaVencimientoEnd();
        this.cuentaPorPagar.cuenta_pasivo_id = this.data.parametrosContables.cuenta_pasivo;
        this.cuentaPorPagar.cuenta_gasto_id = this.data.parametrosContables.cuenta_gasto;
        this.cuentaPorPagar.factura = null;
        this.cuentaPorPagar.importe_actual = 0;
        this.factura.data = {};
      }
      this.cuentaPorPagar.created_at = null;
    },
    cloneObject(object) {
      const objectString = JSON.stringify(object);
      return JSON.parse(objectString);
    },
    validateFechaVencimiento() {
      if (!this.cuentaPorPagar) this.cuentaPorPagar = new Date();
      if (this.cuentaPorPagar.fecha_vencimiento <= this.cuentaPorPagar.fecha) {
        util.showNotify('Seleccione un F/Vencimiento valida!', 'warn'); // 'error'
        this.actualizarFechaVencimientoEnd();
      }
    },
    selectTipoComprobante(selected) {
      this.cuentaPorPagar.tipo_comprobante = selected;
      this.t_comprobante_child = false;
      if (selected.id === 1) { // Factura
        this.cuentaPorPagar.t_comprobante_child = 'F-';
      } else if (selected.id === 4) {
        this.cuentaPorPagar.t_comprobante_child = 'SF-';
      } else {
        this.cuentaPorPagar.tipo_comprobante = null;
      }
    },
    selectMoneda(selected) {
      this.cuentaPorPagar.moneda = selected;
      if (this.cuentaPorPagar.moneda) {
        if (parseInt(selected.is_principal, 10) !== 1) {
          if (!this.cuentaPorPagar.nuevo_tipo_cambio) {
            this.cuentaPorPagar.nuevo_tipo_cambio = selected.tc;
            this.isDisableFieldTC = false;
          }
        } else {
          this.cuentaPorPagar.nuevo_tipo_cambio = null;
          this.isDisableFieldTC = true;
        }
      }
    },
  },
  computed: {
    isInMonedaPrincipal() {
      const monedaSelect = this.cuentaPorPagar.moneda;
      if (monedaSelect) {
        return monedaSelect.is_principal === 1;
      }
      return false;
    },
    ...mapState('main', [
      'user',
    ]),
  },
  watch: {
    /* eslint func-names: ["error", "never"] */
    /*
    moneda: (new1) => {
      if (!new1) return;
      console.log('new', new1);
      if (parseInt(new1.is_principal, 10) !== 1) {
        if (!this.nuevo_tipo_cambio) {
          this.nuevo_tipo_cambio = new1.tc;
        }
      } else {
        this.nuevo_tipo_cambio = null;
      }
    },
    */
    'cuentaPorPagar.descuento': function () {
      this.cuentaPorPagar.importe_neto = parseFloat(this.cuentaPorPagar.importe)
        - parseFloat(this.cuentaPorPagar.descuento);
    },
    'cuentaPorPagar.importe': function (new1) {
      if (!new1) return;
      this.cuentaPorPagar.importe_neto = parseFloat(this.cuentaPorPagar.importe)
        - parseFloat(this.cuentaPorPagar.descuento);
      this.cuentaPorPagar.importe_actual = parseFloat(this.cuentaPorPagar.importe)
      - parseFloat(this.cuentaPorPagar.descuento);
    },
  },
  components: {
    factura,
    DatePicker,
    Multiselect2,
    VueNumeric,
  },
};
</script>

<style scoped>
  ._btn {
    padding: .4rem 1.25rem;
  }
</style>
