<template>
  <b-modal :title="titleModal" size="lg"
    hide-footer
    id='theModalProductoServicios'>
    <div class="row mb-2">
      <div
        :class="isInternation && listaPrecioSeguros.length !== 0
          ? 'col-sm-4' : 'col-sm-8'">
        <div class="row form-group mb-1" style="margin: auto;">
          <label class="col-form-label">Servicios: </label>
          <multiselect2
            track-by="id"
            label="nombre"
            placeholder="Seleccione Servicio"
            :options="servicios"
            :show-labels="false"
            v-model="servicioSelected"
            :loading="loading"
            @input="selectServicio"
            multiple
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="servicioSelected && !(servicioSelected.length===0)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="option" slot-scope="props">
              <small v-if="props.option.is_insurer_service">
                <b>* {{props.option.nombre}}</b>
              </small>
              <small v-else>
                {{props.option.nombre}}
              </small>
            </template>
            >
          </multiselect2>
        </div>
      </div>
      <template v-if="isInternation">
        <template v-if="servicioSelected && servicioSelected.length < 2">
          <template v-if="listaPrecioSeguros.length !== 0">
            <div class="col-sm-2">
              <label class="col-form-label" style="color: red;">
                Precio Seguros:
              </label>
              <multiselect2
                :options="listaPrecioSeguros"
                @input="actualizarInputPrecioSeguro"
                v-model="precioSeguroSelected"
                placeholder=""
                label="nombre" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSeguroSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
              >
              </multiselect2>
            </div>
            <div class="col-sm-2">
              <label class="col-form-label" style="user-select: none"><br></label>
              <input type="text"
                class="form-control"
                :value="inputPrecioSeguro | numeral('0,0.00')"
                autocomplete="off"
                disabled
              >
            </div>
          </template>
          <template v-if="listaPrecios.length !== 0
              && precioSeguroSelected === null">
            <div class="col-sm-2">
              <label class="col-form-label">
                Precio Normal:
              </label>
              <multiselect2
                placeholder="Seleccionar"
                :options="listaPrecios"
                @input="actualizarPrecioNormal"
                :allow-empty="false"
                label="descuento" track-by="id"
                select-label=""
                deselect-label="X"
                v-model="precioNormalSelected"
                >
                <span slot="caret" v-if="!(precioNormalSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect2>
            </div>
            <div class="col-sm-2">
              <label class="col-form-label" style="user-select: none"><br></label>
              <input type="text"
                class="form-control"
                :value="precioMontoNormal| numeral('0,0.00')"
                autocomplete="off" disabled>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="col-sm-3">
            <div class="form-group">
                <label class="col-form-label" style="user-select: none"><br></label>
                <div class="row">
                  <div class="col-sm-6 text-right">
                    <label class="col-form-label"><b>Total:</b></label>
                  </div>
                  <div class="col-sm-6 px-0">
                    <input type="text"
                    class="form-control"
                    :value="getSubTotalModal | numeral('0,0.00')"
                    disabled>
                  </div>
                </div>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button v-if="isInternation"
            type="button"
            class="btn btnBlue mr-2"
            @click="() => { setTipoConsumoProducto(2); agregarItem(); }">
            Prescribir
          </button>
        <button
            class="btn"
            :class="isInternation ? 'btnTurquesa' : 'btn-success'"
            type="button"
            @click="() => { setTipoConsumoProducto(1); agregarItem(); }">
            {{ nombreButton }}
        </button>
    </div>
    </div>
  </b-modal>
</template>
<script>
import { util } from '@/plugins/util';
import Multiselect2 from '@/components/util/vue-multiselect2';
import moment from 'moment';
import {
  mapActions,
  mapState,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheModalProductoServicios',
  props: {
    titleModal: {
      required: true,
      default: null,
    },
    isInternation: {
      type: Boolean,
    },
    seguro: {
      required: false,
      default: null,
    },
    moneda: {
      required: false,
    },
  },
  data() {
    return {
      tipo_consumo_producto: 1, // Valor por defecto, puede ser 1 o 2 según la acción
    };
  },
  created() {
  },
  methods: {
    setTipoConsumoProducto(tipo) {
      this.$emit('setTipoConsumoProducto', tipo);// Emitimos el tipo al padre
    },
    cerrarModal() {
      this.$bvModal.hide('theModalProductoServicios');
      this.reset();
    },
    ...mapActions('theModalServicios', [
      'resetDataTheModaServios',
      'getPrecioServicios',
    ]),
    agregarItem() {
      const servicios = [];
      if (!this.servicioSelected || this.servicioSelected.length === 0) {
        util.showNotify('Debe Seleccionar un servicio', 'warn');
        return;
      }
      // let monto = 0;
      // if (this.precioSeguroSelected) {
      //   monto = this.inputPrecioSeguro;
      // } else {
      //   monto = this.getSubTotalModal;
      // }
      this.servicioSelected.forEach((element, index) => {
        const OBJ = {
          id: 0,
          servicio_complementario_id: this.servicioSelected[index].id,
          estado: true,
          cantidad: 1,
          session: 0,
          numero: this.servicioSelected[index].numero,
          categoria: this.servicioSelected[index].categoria,
          nombre: this.servicioSelected[index].nombre,
          numero_interno: this.servicioSelected[index].numero_interno,
          precio: this.precioSeguroSelected
            ? this.inputPrecioSeguro : this.servicioSelected[index].monto,
          descripcion: this.servicioSelected[index].descripcion_servicio,
          key: `SC - ${new Date().getTime()}`,
        };

        if (this.isInternation) {
          OBJ.fecha_hora = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        servicios.push(OBJ);
      });
      this.$emit('addServiciosComplemetarios', servicios);
      this.cerrarModal();
    },
    async selectServicio(items) {
      const INDICE_ULTIMO_SERVICIO = items.length - 1;
      if (items.length > 0) {
        if (this.isInternation) {
          if (this.precioSeguroSelected && items.length > 1) {
            this.servicioSelected = [items[items.length - 1]];
            this.precioSeguroSelected = null;
            this.inputPrecioSeguro = 0;
          }
          const DTO = {
            aseguradora_id: this.seguro ? this.seguro.id : null,
            servicio_id: items[INDICE_ULTIMO_SERVICIO].tipo_servicio_id,
            tipo_cambio: this.moneda.model.tc,
            tipo_moneda: this.moneda.model.is_principal,
            moneda_id: this.moneda.model.id,
            monedas: this.moneda.multiselect,
          };
          await this.getPrecioServicios(DTO);
        }
        this.$set(this.servicioSelected[INDICE_ULTIMO_SERVICIO], 'monto', this.precioMontoNormal);
      }
    },
    actualizarInputPrecioSeguro() {
      if (this.precioSeguroSelected !== null) {
        this.inputPrecioSeguro = this.precioSeguroSelected.monto;
      } else {
        this.inputPrecioSeguro = 0;
      }
    },
    actualizarPrecioNormal() {
      this.precioMontoNormal = this.precioNormalSelected.monto;
    },
    reset() {
      this.precioSeguroSelected = null;
      this.precioNormalSelected = null;
      this.precioMontoNormal = 0;
      this.inputPrecioSeguro = 0;
    },
  },
  computed: {
    ...mapFields('theModalServicios', [
      'servicios',
      'servicioSelected',
      'precioSeguroSelected',
      'precioNormalSelected',
      'precioMontoNormal',
      'inputPrecioSeguro',
    ]),
    ...mapState('theModalServicios', [
      'loading',
      'listaPrecios',
      'listaPrecioSeguros',
    ]),
    getSubTotalModal() {
      let subTotalModal = 0.00;
      if (this.servicioSelected && this.servicioSelected.length > 0) {
        this.servicioSelected.forEach((item) => {
          subTotalModal = parseFloat(subTotalModal) + parseFloat(item.monto);
        });
      }
      return subTotalModal;
    },
    nombreButton() {
      let nombre = 'Guardar';
      if (this.isInternation) {
        nombre = 'Venta';
      }
      return nombre;
    },
  },
  watch: {
    servicioSelected(items) {
      if (items && items.length === 0) {
        this.reset();
      }
    },
  },
  components: {
    Multiselect2,
  },
  beforeDestroy() {
    this.resetDataTheModaServios();
  },
};
</script>
<style scoped>
.btnBlue {
  color: white;
  background-color: #47404f;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
</style>
